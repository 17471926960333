.form {
  @apply border border-red-500;

  &-group {
    @apply flex flex-col items-start justify-center gap-y-2;
  }

  &-label,
  &-control {
    @apply min-w-full;
  }

  &-label {
    @apply text-sm font-medium text-[#282A2B];
  }

  &-control {
    @apply border border-[#DCE0E3] bg-white;
    @apply outline-none;
    @apply rounded-lg px-4 py-3;

    &__wrapper {
      @apply flex w-full flex-row flex-nowrap items-center justify-between gap-x-2;
      @apply rounded-lg border border-[#DCE0E3] bg-white px-4;

      .form-control {
        @apply min-w-fit flex-grow;
        @apply border-0 px-0;
      }

      div,
      span {
        @apply flex flex-row items-center justify-start;
        @apply text-lg text-[#282A2B];
      }
    }
  }

  &-stepper {
    @apply flex flex-row items-center justify-between md:gap-x-[22px] gap-x-[8px];

    &__item {
      @apply flex flex-row items-center justify-between md:gap-x-[22px] gap-x-[8px];
    }

    &__button {
      @apply flex flex-row items-center justify-start gap-x-3 transition-all;
      @apply opacity-50;

      &:hover,
      &:focus-visible,
      &--active {
        @apply opacity-100;

        [class*='form-stepper__badge'] {
          @apply border border-primary;
        }
      }
    }

    &__badge {
      @apply transition-all;
      @apply flex flex-row items-center justify-center;
      @apply text-sm text-[#282A2B];
      @apply border-[#DCE0E3] bg-white;
      @apply h-8 w-8 rounded-full border-2 text-center;
    }

    &__label {
    }

    &__separator {
      @apply flex aspect-square h-4 w-4 flex-row items-center justify-center text-[#797C80];
    }
  }
}

/**
 * content.scss
 * add className 'content' to the div which contains typography elements
 * e.g. from WYSIWYG
 */

.content {
  @apply text-base font-normal text-[#282A2B];

  @apply flex flex-col items-center justify-start gap-y-4 lg:items-start;

  &--centered {
    @apply items-center justify-center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply text-[#282A2B];
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-lg;
  }

  h6 {
    @apply text-base;
  }

  p {
    @apply text-base;
  }

  ul,
  ol {
    @apply flex flex-col items-start justify-start gap-y-4;
    @apply list-inside list-decimal;
  }

  a {
    @apply transition-all;
    @apply text-primary hover:text-black;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &[class*='heading-alternate'] {
    & > span {
      @apply text-[#476E30];
    }
  }
}

.heading-alternate {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & > span {
      @apply text-[#476E30];
    }
  }
}

@use 'sass:meta';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .pill-button {
    @apply w-full max-w-max rounded-full bg-[#0071E3] px-6 py-[10px] text-base text-white transition duration-150;
  }

  .pill-button:hover {
    @apply bg-primary-dark text-white;
  }
}

@layer base {
  .tag-button {
    @apply rounded-xl bg-[#EFF4FA] px-2 py-1 text-[#0071E3];
  }
  .red-tag-button {
    @apply tag-button;
    @apply bg-[#FAEFF0];
    @apply text-[#E30022];
    @apply text-sm;
  }
  .blue-tag-button {
    @apply mt-3;
    @apply tag-button;
    @apply bg-[#EFF4FA];
    @apply text-[#0071E3];
  }
  .yellow-tag-button {
    @apply mt-3;
    @apply tag-button;
    @apply bg-[#FAF8EF];
    @apply text-[#E3C100];
  }
  .violet-tag-button {
    @apply mt-3;
    @apply tag-button;
    @apply bg-[#F7EFFA];
    @apply text-[#8300E3];
  }
  .green-tag-button {
    @apply mt-3;
    @apply tag-button;
    @apply bg-[#EFFAEE];
    @apply text-[#00E34D];
  }
  .orange-tag-button {
    @apply mt-3;
    @apply tag-button;
    @apply bg-[#FAF3EF];
    @apply text-[#E38C00];
  }
}

@layer utilities {
  // required to set tailwind inheritence priority higher than components or base
  .rounded-solid-button {
    @apply ml-auto inline-flex w-full max-w-max items-center gap-1 rounded-full bg-primary px-3 py-2 text-sm text-white hover:bg-primary-dark;
  }
}

body {
  @apply text-sm font-normal;

  .link {
    @apply border-b border-b-black transition-all hover:border-b-black/0;
  }
}

/* scroll barr  */
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#ced4da),
    to(#ced4da),
    color-stop(0.6, #ced4da)
  );
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.collapse {
  visibility: unset;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.active {
  border-color: #666;
}
.product-slider {
  @apply w-full flex-col items-center justify-start;
  .slick-slide {
    visibility: hidden;
  }
  .slick-active {
    visibility: visible;
  }
  .slick {
    &-list {
      @apply relative aspect-square w-full flex-col items-center justify-start gap-12 rounded-[20px] bg-[#F4F6F8] pt-32;
    }
    &-arrow {
      &--prev,
      &--next {
        @apply absolute top-[50%] z-[2] flex size-9 -translate-y-[50%] cursor-pointer flex-row items-center justify-center rounded-full bg-white text-sm transition-all hover:bg-white hover:opacity-80 md:size-14 md:text-lg;
      }

      &--prev {
        left: calc(30px);

        @media screen and (max-width: 767px) {
          left: 20px;
        }
      }

      &--next {
        right: calc(30px);

        @media screen and (max-width: 767px) {
          right: 20px;
        }
      }
    }

    &-dots {
      @apply mx-auto flex w-full flex-row gap-1 px-5 md:w-fit md:px-0;
      @apply absolute bottom-5 left-[50%] -translate-x-[50%];

      display: flex !important;

      & > li {
        @apply relative mx-0 h-fit w-fit grayscale transition-all hover:grayscale-0;

        &.slick-active {
          @apply grayscale-0;
        }
      }
    }
    &-track {
      display: flex;
    }
    &-slide {
      @apply opacity-[.90] transition-all;

      &.slick-active {
        @apply opacity-100;
      }
    }
  }
}

.product-cour-slide {
  @apply min-h-[584px] w-full items-center justify-start gap-y-12;

  .slick-list {
    @apply h-full;
    min-height: 600px !important;
  }

  .slick-track {
    @apply h-full;
  }

  .slick-slide {
    visibility: hidden;
  }
  .slick-active {
    visibility: visible;
  }
  .slick {
    &-arrow {
      &--prev,
      &--next {
        @apply relative top-[50%] z-[2] flex size-9 cursor-pointer flex-row items-center justify-center rounded-full bg-white text-sm transition-all hover:bg-slate-600 hover:opacity-80 md:size-10 md:text-lg;
      }

      &--prev {
        position: absolute;
        left: 88%;
        top: 93%;
        background-color: black;
        color: white;

        @media screen and (max-width: 767px) {
          left: 20px;
        }
      }

      &--next {
        left: 92%;
        background-color: black;
        color: white;

        @media screen and (max-width: 767px) {
          right: 20px;
        }
      }
    }

    &-dots {
      @apply mx-auto grid w-full grid-cols-4 gap-6 px-5 md:w-fit md:gap-9 md:px-0;
      @apply absolute -top-32 bottom-auto left-[50%] -translate-x-[50%];

      display: grid !important;

      & > li {
        @apply relative mx-0 h-fit w-fit grayscale transition-all hover:grayscale-0;

        &.slick-active {
          @apply grayscale-0;
        }
      }
    }
    &-track {
      display: flex;
      column-gap: 20px;
    }
    &-slide {
      @apply w-[400px] opacity-[.32] transition-all;

      &.slick-active {
        @apply opacity-100;
      }
    }
  }
}
.gsap-swipe-up {
  overflow: hidden;
  div {
    display: block;
    object-fit: cover;
  }
}

.card-fade-on-scroll {
  opacity: 0.3;
}

.verticalLine {
  border-left: 1px solid #00000029;
  height: 20px;
}

.swiper-hero {
  .swiper-pagination {
    @apply bottom-6 left-[45%] w-fit rounded-3xl bg-white p-1 #{!important};
  }
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.visible-sticky {
  transform: translateY(0);
}

.hidden-sticky {
  transform: translateY(-100%);
}
.sections-par {
  width: 100%;
  position: relative;
  height: 35vh;
  display: flex;
  justify-content: center;
}

.panels-parallax {
  width: 100%;
  /*   height: 100vh; */
  overflow: hidden;
  position: relative;
}

.panel-parallax {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  position: absolute;
  top: 0;
  left: 0;
}

/* Menghilangkan ikon panah pada browser Webkit (Chrome, Safari, dll.) */
input[type='number'].hidden-input-number::-webkit-outer-spin-button,
input[type='number'].hidden-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Menghilangkan ikon panah pada Firefox */
input[type='number'].hidden-input-number {
  -moz-appearance: textfield;
}

@include meta.load-css('blockquote.scss');
@include meta.load-css('content.scss');
@include meta.load-css('form.scss');
@include meta.load-css('geocool-slider.scss');

.prevent-scrollbar-layout-shift {
  padding-left: calc(100vw - 100%);
}

.geocool-testimonial {
  @apply overflow-visible;

  .slick {
    &-slider {
      @apply relative bg-[#F4F7F1] transition-all;
    }
  }
}

.geocool-slider {
  @apply flex w-full flex-col items-center justify-start gap-y-12;

  .slick {
    &-arrow {
      &--prev,
      &--next {
        @apply absolute top-[50%] z-[2] flex size-9 -translate-y-[50%] cursor-pointer flex-row items-center justify-center rounded-full bg-white text-sm transition-all hover:bg-white hover:opacity-80 md:size-14 md:text-lg;
      }

      &--prev {
        left: calc(120px + 60px);

        @media screen and (max-width: 767px) {
          left: 20px;
        }
      }

      &--next {
        right: calc(120px + 60px);

        @media screen and (max-width: 767px) {
          right: 20px;
        }
      }
    }

    &-dots {
      @apply mx-auto grid w-full grid-cols-4 gap-6 px-5 md:w-fit md:gap-9 md:px-0;
      @apply absolute -top-32 bottom-auto left-[50%] -translate-x-[50%];

      display: grid !important;

      & > li {
        @apply relative mx-0 h-fit w-fit grayscale transition-all hover:grayscale-0;

        &.slick-active {
          @apply grayscale-0;
        }
      }
    }

    &-slide {
      @apply opacity-[.32] transition-all;

      &.slick-active {
        @apply opacity-100;
      }
    }
  }
}

.geocool-product-slider {
  @apply flex w-full flex-col items-center justify-start gap-y-12;

  .slick {
    &-arrow {
      &--prev,
      &--next {
        @apply absolute top-[50%] z-[2] flex size-9 -translate-y-[50%] cursor-pointer flex-row items-center justify-center rounded-full bg-white text-sm transition-all hover:bg-white hover:opacity-80 md:size-14 md:text-lg;
      }

      &--prev {
        left: calc(10px + 10px);

        @media screen and (max-width: 767px) {
          left: 20px;
        }
      }

      &--next {
        right: calc(10px + 10px);

        @media screen and (max-width: 767px) {
          right: 20px;
        }
      }
    }

    &-dots {
      @apply mx-auto grid w-full grid-cols-4 gap-6 px-5 md:w-fit md:gap-9 md:px-0;
      @apply absolute -top-32 bottom-auto left-[50%] -translate-x-[50%];

      display: grid !important;

      & > li {
        @apply relative mx-0 h-fit w-fit grayscale transition-all hover:grayscale-0;

        &.slick-active {
          @apply grayscale-0;
        }
      }
    }
  }
}
